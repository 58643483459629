// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'stylesheets/application.scss'

require('bugsnag')

import { setupScanner } from 'sample_tests'
import { fileUpload } from 'file_upload'
import { disableButtonWith, confirmSubmission, disableInvalidSubmits } from 'forms'

// // Uncomment to copy all static images under ../images to the output folder and reference
// // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// // or the `imagePath` JavaScript helper below.
// //
const images = require.context('images', true)
const imagePath = (name) => images(name, true)

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.qr-scanner').forEach(wrapper => {
    setupScanner(wrapper)
  })

  document.querySelectorAll('.file-upload').forEach(fileInput => {
    fileUpload(fileInput)
  })

  // rails-ujs replacements
  document.querySelectorAll('[data-confirm]').forEach(confirmSubmission)
  document.querySelectorAll('[data-disable-with]').forEach(disableButtonWith)

  // Keep buttons disabled until a form is valid
  document.querySelectorAll('button').forEach(disableInvalidSubmits)

})
