export const displayError = (wrapperElement) => (el, msg) => {
  wrapperElement.classList.add('error')

  el.classList.add('error')
  el.innerHTML = "<strong>Scanning Error</strong>: " + msg
}

export const displayMessage = (el, msg) => {
  el.innerHTML = msg
}
