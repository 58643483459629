import Bugsnag from '@bugsnag/js'

// Use a JSON object in the ENV to transparently configure bugsnag
// This is rewritten at _build_ time only, not run time.
const config = process.env.BUGSNAG_JS_CONFIG
try {
  if (config) {
    Bugsnag.start(JSON.parse(config))
  }
} catch(e) {
  console.error(e)
}
