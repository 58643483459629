// Quick and dirty copy of Rails data-confirm logic, except run on form submit
// rather than button click
// https://github.com/rails/rails/tree/b3646fb973bd72c6bdb262fad86830fb2e16c46f/actionview/app/assets/javascripts/rails-ujs
export const confirmSubmission = (element) => {
  handleSubmission(element, (e) => {
    if (!confirm(element.dataset.confirm)) {
      e.preventDefault()
      e.stopPropagation()
      e.stopImmediatePropagation()
      return false
    }
  })
}

// Quick and dirty copy of Rails data-disable-with logic
// https://github.com/rails/rails/tree/b3646fb973bd72c6bdb262fad86830fb2e16c46f/actionview/app/assets/javascripts/rails-ujs
export const disableButtonWith = (button) => {
  handleSubmission(button, () => {
    button.textContent = button.dataset.disableWith
    button.disabled = true
  })
}

// Keep a submit button's disabled state in sync with the validity of its form
export const disableInvalidSubmits = (button) => {
  if ( !button.form ) return;
  button.form.addEventListener('input', (e) => { validateSubmitButton(button) })
  button.form.addEventListener('change', (e) => { validateSubmitButton(button) })
  validateSubmitButton(button)
}

const validateSubmitButton = (button) => {
  button.disabled = ! button.form.checkValidity()
}

const handleSubmission = (element, callback) => {
  const form = element.form
  if ( form ) {
    form.addEventListener('submit', callback)
  }
}
