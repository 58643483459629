// import QrScanner from "qr-scanner"
import jsQR from "jsqr"
import { displayError, displayMessage } from "./message"
import { sslCheck } from "./scanner-checks"

export const setupScanner = (wrapperElement) => {
  var messageElement = wrapperElement.getElementsByClassName("status")[0]
  var buttonElement = document.createElement("button")

  const onError = displayError(wrapperElement)

  if (!navigator.mediaDevices) {
    return onError(messageElement, "Scanning isn't supported by this browser on this device.")
  }

  if (sslCheck()) {
    buttonElement.innerText = 'Scan Code'
    buttonElement.onclick = () => {
      buttonElement.remove()
      startScanner({ wrapperElement, messageElement, onError })
    }
    displayMessage(messageElement, '')
    wrapperElement.appendChild(buttonElement)
  } else {
    onError(
      messageElement,
      "Scanning requires a secure connection"
    )
  }

}

const onScan = (code, video, messageElement) => {
  if ( !code.data ) {
    displayMessage(messageElement, 'Invalid barcode')

  } else if ( !code.data.match(location.hostname) ) {
    displayMessage(messageElement, `Invalid code scanned: ${code.data}`)

  } else {
    // as it may take a moment to redirect on slower connections
    displayMessage(messageElement, 'Found code. Redirecting…')
    video.hidden = true
    window.location.href = code.data
  }
}

const startScanner = ({ wrapperElement, messageElement, onError }) => {
  var video = document.createElement("video")
  var canvasElement = document.createElement("canvas")
  var canvas = canvasElement.getContext("2d")

  displayMessage(messageElement, "Loading video…")

  navigator.mediaDevices
    // Use facingMode: environment to attemt to get the front camera on phones
    .getUserMedia({ video: { facingMode: "environment" } })
    .then((stream) => {
      wrapperElement.appendChild(video)

      video.srcObject = stream
      video.setAttribute("playsinline", true) // required to tell iOS safari we don't want fullscreen
      video.play()

      loopScan()
    })
    .catch(() => {
      // In case we don't have access to the camera
      onError(messageElement, `
        Couldn't access camera.
        <button onclick="window.location.reload()">Reload page</button>
      `)
    })

  // Periodically check for QR codes
  const loopScan = () => {
    var code = readCode()

    if (code) {
      onScan(code, video, messageElement)
      // Pause if there was a code so the user can read the message
      setTimeout(loopScan, 1000)
    } else {
      displayMessage(messageElement, "Scanning…")
      requestAnimationFrame(loopScan)
    }
  }

  const readCode = () => {
    if ( video.hidden ) return;
    if ( video.readyState !== video.HAVE_ENOUGH_DATA ) return;

    canvasElement.height = video.videoHeight;
    canvasElement.width = video.videoWidth;

    canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height)
    var imageData = canvas.getImageData(
      0,
      0,
      canvasElement.width,
      canvasElement.height
    )
    return jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: "dontInvert",
    })
  }
}
