import '@uppy/core/dist/style.css'
import '@uppy/status-bar/dist/style.css'

import Core from '@uppy/core'
import StatusBar from '@uppy/status-bar'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import AwsS3 from '@uppy/aws-s3'

export const fileUpload = (fileInput) => {
  const formGroup = fileInput.labels[0].parentNode,
        hiddenInput = document.createElement('input'),
        img = document.createElement('img'),
        statusBar = document.createElement('div'),
        imagePreview = document.createElement('div'),
        uploadBtn = document.getElementById('upload-button')

  fileInput.hidden = true
  hiddenInput.type = 'text' // Use text instead of hidden so that our form validations work
  hiddenInput.name = fileInput.name
  hiddenInput.hidden = true
  hiddenInput.required = fileInput.required
  fileInput.required = false

  formGroup.appendChild(hiddenInput)
  formGroup.appendChild(statusBar)
  formGroup.appendChild(imagePreview)

  const uppy = new Core({
      autoProceed: true,
    })
    .use(StatusBar, {
      target: statusBar,
      replaceTargetContent: true,
      hideAfterFinish: false,
    })
    .use(ThumbnailGenerator)
    .use(AwsS3, {
      companionUrl: '/', // will call the presign endpoint on `/s3/params`
    })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show preview of the image using URL from thumbnail generator
    img.src = preview
    img.style.opacity = 0.25
    imagePreview.appendChild(img)
  })

  uppy.on('upload', (data) => {
    hiddenInput.value = ''
    fileInput.disabled = true
    uploadBtn.classList.add('uploading')
  })
  uppy.on('complete', (result) => {
    uploadBtn.classList.remove('uploading')
    fileInput.disabled = false
  })

  uppy.on('cancel-all', () => {
    img.remove()
    uploadBtn.classList.remove('uploading')
    fileInput.disabled = false
  })

  uppy.on('upload-success', (file, response) => {
    // construct uploaded file data in the format that Shrine expects
    const uploadedFileData = {
      id: file.meta['key'].match(/^.*cache\/(.+)/)[1], // object key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    }
    img.style.opacity = 1
    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    updateInput(hiddenInput, JSON.stringify(uploadedFileData))
  })

  fileInput.addEventListener('change', (event) => {
    const files = Array.from(event.target.files)

    uppy.reset()
    updateInput(hiddenInput, null)

    files.forEach((file) => {
      try {
        uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
        // Remove the file from the input so we don't POST it with the form
        fileInput.value = null;
      } catch (err) {
        if (err.isRestriction) {
          // handle restrictions
          console.error('Restriction error:', err)
        } else {
          // handle other errors
          console.error(err)
        }
      }
    })
  })
}

const updateInput = (input, value) => {
  input.value = value
  input.dispatchEvent(new Event('input', { 'bubbles': true }));
}
